import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import {Grid,Col,Row,Image,Button,FormGroup,FormControl} from 'react-bootstrap';
import * as firebase from 'firebase';
import Backend from '../backend.js'
import Logo from '../img/logo.png';

class IndexLogIn extends Component {
  constructor(props){
    super(props);
    this.state = {
      email:'',
      password:'',
    }
  }

  _login = () => {
    const self = this;
    if (self.state.email !== '' || self.state.password !== '') {
      Backend.loginAurea(self.state.email,self.state.password, response => {
        if(response === 'Acceso'){
          localStorage.setItem("session",true);
          self.props.history.push('/home');
        }else{

        }
      })
    }else{
      alert('Please fill all the fields');
    }
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this._login();
    }
  }

  render() {
    return (
      <div className={'backgroundLI'}>
        <Grid>
          <Row>
            <Col xs={12} md={12} className="centerDiv">
              <center><Image src={Logo} className="widthLogoBannerLogIn"responsive/></center>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} mdOffset={3} className="divBackgroundLogIn">
                <FormGroup controlId="formBasicText"className="marginFormInputsLogIn1">
                  <FormControl
                    className="inputStyleLogIn"
                    type="text"
                    value={this.state.email}
                    placeholder={'Login'}
                    onChange={(email)=>{this.setState({email:email.target.value})}}
                    onKeyPress={this.handleKeyPress}
                  />
                  <FormControl.Feedback />
                </FormGroup>
                <FormGroup controlId="formBasicText"className="marginFormInputs">
                  <FormControl
                    className="inputStyleLogIn"
                    type="password"
                    value={this.state.password}
                    placeholder={'Password'}
                    onChange={(password)=>{this.setState({password:password.target.value})}}
                    onKeyPress={this.handleKeyPress}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              <Button bsStyle="primary"className="buttonRegisterBanner"onClick={this._login}>Login</Button>
            </Col>
            {/*<Row>
              <Col xs={12} md={12} className="centerDiv">
                <br/>
                <p className={'footerTerms'}>© 2018 All rights reserved - GNT Capital</p>
              </Col>
            </Row>*/}
          </Row>
        </Grid>
      </div>
    );
  }
}

export default IndexLogIn;
