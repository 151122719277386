import React, { Component } from 'react';
import { Col, Row, FormControl, DropdownButton, MenuItem, Button, FormGroup, ControlLabel, Tabs, Modal, Tab} from 'react-bootstrap';
import './style.css';
import Backend from '../../../backend';
import ArrowIcon from '../../../img/arrow--44.png';
import LoadImage from '../../../img/image-01.svg';
import NoImage from '../../../img/noimage-01.svg';
import EditImage from '../../../img/imageedit.svg';
import CheckImage from '../../../img/check.svg';
import CheckWImage from '../../../img/checkW.svg';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import GalleryModal from './gallery';
import backend from '../../../backend';
import { store } from 'react-notifications-component';


var AWS = require('aws-sdk');
var BUCKET_WIREBIT = String('gnt-s3-bucket');

var s3 = new AWS.S3({
  region: String('us-east-1'),
  accessKeyId: String('AKIARP6AQQKX5WINXGRA'),
  secretAccessKey: String('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'),
});

let directorsDocuments = []
let directorsDocumentsExtention = []
let shareholdersDocuments = []
let shareholdersDocumentsExtention = []


class ClientRequests extends Component {
  constructor(props) {
    super(props)
    this.state = {
      origin: this.props.client.empresa,
      expanded: false,
      open: false,
      ine1Img: LoadImage,
      ine2Img: LoadImage,
      cc1Img: LoadImage,
      cc2Img: LoadImage,
      pasaporteImg: LoadImage,
      estadocuentaImg: LoadImage,
      domicilioImg: LoadImage,
      contratoImg:LoadImage,
      img1_VI: false,
      img2_VI: false,
      img3_VI: false,
      img4_VI: false,
      img5_VI: false,
      img0_VI:false,
      img6_VI:false,
      selectedFile: null,
      listaAllCustomer: [],
      idCliente: this.props.client.idgnt_whiteLevel_clientesInfo,
      nameCustomer: this.props.client.clienteNombre,
      lastaNameCustomer: this.props.client.clientesApellidos,
      countryCustomer: this.props.client.nombrePais,
      phoneCustomer: this.props.client.clienteTelefono,
      addressCustomer: this.props.client.Datos,
      emailCustomer: this.props.client.clienteEmail,
      approvedCustomer:this.props.client.approved,
      documentsCustomer:this.props.client.documents,
      cuentaMetaCustomet:this.props.client.cuentasMeta,
      searchFilterText: '',
      currentIndex: null,
      file: '',
      imagePreviewUrl: '',
      documentType: '',
      isValid: false,
      currentIndex2:null,
      actualTab:'directors',
      companyDocName:'',
      compDocImg:LoadImage,
      companyDoc:'Company Doc',
      shareholderValue:'Shareholder 1',
      directorValue:'Director 1',
      shareholInfo:[],
      actualshareholInfo:[],
      directorInfo:[],
      actualdirectorInfo:[],
      direcine1Img: LoadImage,
      direcine2Img: LoadImage,
      direcpasaporteImg: LoadImage,
      direcestadocuentaImg: LoadImage,
      direcdomicilioImg: LoadImage,
      shareine1Img: LoadImage,
      shareine2Img: LoadImage,
      sharepasaporteImg: LoadImage,
      shareestadocuentaImg: LoadImage,
      sharedomicilioImg: LoadImage,
      actualShareNum:0,
      actualDirectNum:0,
    }

    this.closeModal = this.closeModal.bind(this);
    this.findNext = this.findNext.bind(this);
    this.findPrev = this.findPrev.bind(this);
    
    this.closeModal2 = this.closeModal2.bind(this);
    this.findNext2 = this.findNext2.bind(this);
    this.findPrev2 = this.findPrev2.bind(this);

  }

  openModal(index) {
    this.setState({ currentIndex: index });
  }

  closeModal(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    this.setState({ currentIndex: null });
  }

  findPrev(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex - 1
    }));
  }

  findNext(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1
    }));
  }

  openModal2(index2) {
    this.setState({ currentIndex2: index2 });
  }

  closeModal2(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    this.setState({ currentIndex2: null });
  }

  findPrev2(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    this.setState(prevState => ({
      currentIndex2: prevState.currentIndex2 - 1
    }));
  }

  findNext2(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    this.setState(prevState => ({
      currentIndex2: prevState.currentIndex2 + 1
    }));
  }

  getShareDirectorsInfo(){
    Backend.getShareDirectors(this.state.idCliente, response => {
      this.setState({directorInfo:response.infoDirectors,shareholInfo:response.infoShareholders,shareholderValue: (response.infoShareholders[0].nombre + ' '+ response.infoShareholders[0].apellidos), directorValue: (response.infoDirectors[0].nombre + ' '+ response.infoDirectors[0].apellidos)})
      this.getDocsKeys();
      this.getImageStatus();
    })
  }

  componentWillMount() {
    if(this.state.origin == 1) {
      this.getShareDirectorsInfo()
    }
    else {
      this.getDocsKeys();
      this.getImageStatus();  
    }
    var id
    if(this.state.idCliente != undefined) id = this.state.idCliente
    else id = 0
    Backend.contractHandler(id,'VA', response => {
      //console.log(response);
    })
  }

  getDocsKeys(){
    const self = this;
    var id_group = 'J2A/' + String(this.state.idCliente) + '/';
    var params = {
      Bucket: BUCKET_WIREBIT, /* required */
      Prefix: id_group  // Can be your folder name
    };

    s3.listObjectsV2(params, function (err, data) {
      if(err) console.log('There was an error viewing your album: '+err.message);
      else{
        var Docs = data.Contents;
        Docs.forEach(element => {
          self.getDocsImg(element.Key)
        })
        self.setState({showLoad:false})
      }
    })
  }

  getDocsImg(key){
    const self = this
    var imgParams = { Bucket: BUCKET_WIREBIT, Key: key }
    s3.getObject(imgParams, function (err, data) {
      if (err) {
        return err;
        /* Cliente o IB */
      } else if(self.state.origin != 1) {
        let ContentType = (data.ContentType == 'application/octet-stream') ?'application/pdf' : data.ContentType
        var file = new Blob([data.Body], {type:ContentType});
        var fileURL = URL.createObjectURL(file);

        var tipoDoc = key.split(".")[0].split('/')[2].split('_')[0].toLowerCase() + 'Img'
        tipoDoc = tipoDoc.replace(' ','')
        var object = {}
        object[tipoDoc] = fileURL
        object[tipoDoc + 'Date'] = data.LastModified;
        object[tipoDoc + 'Extention'] = key.toLowerCase().split(".")[1];

        if(self.state[tipoDoc + 'Date']){
          if(self.state[tipoDoc + 'Date'] < data.LastModified) self.setState(object)
        }else{
          self.setState(object)
        }
      }
      /* Empresa */
      else {
        let ContentType = (data.ContentType == 'application/octet-stream') ?'application/pdf' : data.ContentType
        var file = new Blob([data.Body], {type:ContentType});
        var fileURL = URL.createObjectURL(file);
        var companyDoc = key.split(".")[0].split('/')[2].split('_')[0]

        /* DOcumentos de la empresa */
        if((key.split("/").length == 3 )){
          if(companyDoc != 'Contrato'){
            companyDoc = self.setCompanyDocName(companyDoc)
            self.setState({companyDoc, companyDocName:key.split(".")[0].split('/')[2].split('_')[0]})
          }
          if(companyDoc == 'Contrato') var tipoDoc = key.split(".")[0].split('/')[2].split('_')[0].toLowerCase() + 'Img'
          else var tipoDoc = 'compDocImg'
          tipoDoc = tipoDoc.replace(' ','')
          var object = {}
          object[tipoDoc] = fileURL
          object[tipoDoc + 'Date'] = data.LastModified;
          object[tipoDoc + 'Extention'] = key.toLowerCase().split(".")[1];

          if(self.state[tipoDoc + 'Date']){
            if(self.state[tipoDoc + 'Date'] < data.LastModified) self.setState(object)
          }else{
            self.setState(object)
          }
        }
        /* Documentos de los Directores y Shareholders */
        else {
          /* DOcumentos de los DIrectores */
          if(key.split("/")[2] == 'Directors'){

            var tipoDoc = key.split(".")[0].split('/')[4].split('_')[0].toLowerCase() + 'Img'
            tipoDoc = tipoDoc.replace(' ','')

            if(tipoDoc != 'Img') {

              let directorInfo = self.state.directorInfo
              self.state.directorInfo.forEach((item,i) => {
                if(key.split("/")[3] == 'DR'+(i+1)) {
                  directorInfo[i][tipoDoc] = fileURL
                  directorInfo[i][tipoDoc + 'Date'] = data.LastModified;
                  directorInfo[i][tipoDoc + 'Extention'] = key.toLowerCase().split(".")[1];
                }

                if(self.state[tipoDoc + 'Date']){
                  if(self.state[tipoDoc + 'Date'] < data.LastModified) {
                    self.setState(directorInfo)
                    if(self.state.actualDirectNum != 0) self.getData('directors',self.state.directorInfo[self.state.actualDirectNum],self.state.actualDirectNum)
                    else self.getData('directors',self.state.directorInfo[0],0)
                  }
                }else{
                    self.setState(directorInfo)
                    if(self.state.actualDirectNum != 0) self.getData('directors',self.state.directorInfo[self.state.actualDirectNum],self.state.actualDirectNum)
                    else self.getData('directors',self.state.directorInfo[0],0)
                }
                
              });
            }
          }
          /* Documentos de los Shareholders */
          else {
            var tipoDoc = key.split(".")[0].split('/')[4].split('_')[0].toLowerCase() + 'Img'
            tipoDoc = tipoDoc.replace(' ','')

            if(tipoDoc != 'Img') {
              let shareholInfo = self.state.shareholInfo
              self.state.shareholInfo.forEach((item,i) => {
                if(key.split("/")[3] == 'SH'+(i+1)) {
                  shareholInfo[i][tipoDoc] = fileURL
                  shareholInfo[i][tipoDoc + 'Date'] = data.LastModified;
                  shareholInfo[i][tipoDoc + 'Extention'] = key.toLowerCase().split(".")[1];
                }
                if(self.state[tipoDoc + 'Date']){
                  if(self.state[tipoDoc + 'Date'] < data.LastModified) {
                    self.setState(shareholInfo)
                    if(self.state.actualShareNum != 0) self.getData('shareholder',self.state.shareholInfo[self.state.actualShareNum],self.state.actualShareNum)
                    else self.getData('shareholder',self.state.shareholInfo[0],0)
                  }
                }else{
                    self.setState(shareholInfo)
                    if(self.state.actualShareNum != 0) self.getData('shareholder',self.state.shareholInfo[self.state.actualShareNum],self.state.actualShareNum)
                    else self.getData('shareholder',self.state.shareholInfo[0],0)
                }
              });
            }

            
          }
        }
        
      }
    });
  }

  setCompanyDocName(documento){
    switch (documento) {
      case 'articlesOrg':
        return 'Articles of Organizations'
      case 'articlesCorp':
        return 'Articles of Corportation'
      case 'trustDoc':
        return 'Trust'
    }
  }

  getImageStatus() {
    this.setState({
      showLoad: true,
    });

    var images = {}
    Backend.getImagesIDs(this.state.idCliente, response => {
      response = JSON.parse(response)
      response.forEach((item, i) => {
        if(item.tipoInfo == "CL"){
          if(item.tipo=="Ine 1"){
            this.setState({img1_VI: item.estatus})
          }
          else if(item.tipo=="Ine 2"){
            this.setState({img2_VI: item.estatus})
          }
          else if(item.tipo=="Pasaporte"){
            this.setState({img3_VI: item.estatus})
          }
          else if(item.tipo=="Estado cuenta"){
            this.setState({img4_VI: item.estatus})
          }
          else if(item.tipo=="Domicilio"){
            this.setState({img5_VI: item.estatus})
          }
          else if(item.tipo=="Contrato"){
            this.setState({img0_VI: item.estatus})
          }
          else if(item.tipo=="articlesOrg" || item.tipo=="articlesCorp" || item.tipo=="trustDoc"){
            this.setState({img6_VI: item.estatus})
          }
        }
        else {
          if(item.tipoInfo.includes('SH')){
            var numero = item.tipoInfo.split('SH')[1] 
            var shareholInfo = this.state.shareholInfo
            
            if(item.tipo=="Ine 1"){
              shareholInfo[numero -1]['ine1ImgEstatus'] = item.estatus
              this.setState({shareholInfo})
            }
            else if(item.tipo=="Ine 2"){
              shareholInfo[numero -1]['ine2ImgEstatus'] = item.estatus
              this.setState({shareholInfo})
            }
            else if(item.tipo=="Pasaporte"){
              shareholInfo[numero -1]['pasaporteImgEstatus'] = item.estatus
              this.setState({shareholInfo})
            }
            else if(item.tipo=="Estado cuenta"){
              shareholInfo[numero -1]['estadocuentaImgEstatus'] = item.estatus
              this.setState({shareholInfo})
            }
            else if(item.tipo=="Domicilio"){
              shareholInfo[numero -1]['domicilioImgEstatus'] = item.estatus
              this.setState({shareholInfo})
            }
          }
          else {
            var numero = item.tipoInfo.split('DR')[1] 
            var directorInfo = this.state.directorInfo
            
            if(item.tipo=="Ine 1"){
              directorInfo[numero -1]['ine1ImgEstatus'] = item.estatus
              this.setState({directorInfo})
            }
            else if(item.tipo=="Ine 2"){
              directorInfo[numero -1]['ine2ImgEstatus'] = item.estatus
              this.setState({directorInfo})
            }
            else if(item.tipo=="Pasaporte"){
              directorInfo[numero -1]['pasaporteImgEstatus'] = item.estatus
              this.setState({directorInfo})
            }
            else if(item.tipo=="Estado cuenta"){
              directorInfo[numero -1]['estadocuentaImgEstatus'] = item.estatus
              this.setState({directorInfo})
            }
            else if(item.tipo=="Domicilio"){
              directorInfo[numero -1]['domicilioImgEstatus'] = item.estatus
              this.setState({directorInfo})
            }
          }
        }
        
      });
      this.setState({showLoad:false})
    });

  }

  CheckDoc(document,img, key){
    document=!document;
    if(img===1)
    this.setState({img1_VI:document})
    else if(img===2)
    this.setState({img2_VI:document})
    else if(img===3)
    this.setState({img3_VI:document})
    else if(img===4)
    this.setState({img4_VI:document})
    else if(img===5)
    this.setState({img5_VI:document})
    else if(img===6)
    this.setState({img6_VI:document})

    let ruta;
    ruta = key + '_' + this.state.idCliente + '_OK.'
    let operacion;

    if(document) operacion='V';
    else operacion='I';
    Backend.documentApproverv2(operacion,ruta,this.state.idCliente, 'CL' ,response => {
      response = JSON.parse(response)
    })

  }

  CheckShareDirDoc(document, img, key, origin, posicion){
    document=!document;
    var keysito = key
    var newKey = this.getrevertName(key)
    let ruta;
    if(origin == 'shareholders') ruta = 'Shareholders/SH'+posicion +'/' + newKey + '_' + this.state.idCliente + '_OK.'
    else  ruta = 'Directors/DR'+posicion +'/' + newKey + '_' + this.state.idCliente + '_OK.'
    let operacion;
    if(document) operacion='V';
    else operacion='I'

    if(origin == 'shareholders'){
      Backend.documentApproverv2(operacion,ruta,this.state.idCliente, 'SH' ,response => {
        response = JSON.parse(response)
          let actualshareholInfo = this.state.actualshareholInfo
          let shareholInfo = this.state.shareholInfo
          actualshareholInfo[keysito+'Estatus'] = document
          shareholInfo[posicion-1][keysito+'Estatus'] = document
          this.setState({actualshareholInfo, shareholInfo})
      })
    }
    else {
      Backend.documentApproverv2(operacion,ruta,this.state.idCliente, 'DR' ,response => {
        response = JSON.parse(response)
        let actualdirectorInfo = this.state.actualdirectorInfo
        let directorInfo = this.state.directorInfo
        actualdirectorInfo[keysito+'Estatus'] = document
        directorInfo[posicion-1][[keysito]+'Estatus'] = document
        this.setState({actualdirectorInfo, directorInfo})
      })
    }
  }

  CheckContract(checked){
    checked=!checked
    this.setState({img0_VI:checked})
    Backend.contractHandler(this.state.idCliente,'VV', response => {
      //console.log(response);
    })
  }

  showError(error){
    store.addNotification({
      title: "Error",
      message: error,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    })
  }
  
  showSuccess(success){
    store.addNotification({
      title: "Success!",
      message: success,
      type: "success",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    })
  }

  showWarning(warning){
    store.addNotification({
      title: "Warning",
      message: warning,
      type: "warning",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    })
  }

  UploadImage(Data,key){
    const self = this;
    self.setState({ spinner: true });
    var Params;
    var separador = Data.type.split("/");
    var newKey = key

    key =  'J2A/' + this.state.idCliente + '/' + key + '_' + this.state.idCliente + '_OK.' + separador[1]
    Params = {Bucket: BUCKET_WIREBIT, Key: key, Body:Data, ContentType:Data.type}

    if(this.state[key + 'Img'] == NoImage){
      s3.putObject(Params, function (err, data) {
        if (err) {
          console.log(err);
        } else {
          self.getDocsKeys();
          Backend.sendUploadImage(self.state.idCliente, newKey, data.Location,response => console.log(response))
        }
      })
    }else{
      s3.upload(Params, function (err, data) {
        if (err) {
          console.log(err);
        } else {
          self.getDocsKeys();
          Backend.sendUploadImage(self.state.idCliente, newKey, data.Location,response => console.log(response))
        }
      })
    }
  }

  UploadImageShareDic(Data,key,origin, posicion){
    const self = this;
    self.setState({ spinner: true });
    var Params;
    var separador = Data.type.split("/");
    var newKey = this.getrevertName(key)
    let ruta;

    if(origin == 'shareholders') ruta = 'Shareholders/SH'+posicion +'/' + newKey + '_' + this.state.idCliente + '_OK.'
    else  ruta = 'Directors/DR'+posicion +'/' + newKey + '_' + this.state.idCliente + '_OK.'

    key =  'J2A/' + this.state.idCliente + '/' + ruta + separador[1]
    Params = {Bucket: BUCKET_WIREBIT, Key: key, Body:Data, ContentType:Data.type}
    console.log('key', key) //quitar
    /*  */
    if(this.state[key + 'Img'] == NoImage){
      s3.putObject(Params, function (err, data) {
        if (err) {
          console.log(err);
        } else {
          self.getDocsKeys();
          Backend.sendUploadImage(self.state.idCliente, newKey, data.Location,response => console.log(response))
        }
      })
    }else{
      s3.upload(Params, function (err, data) {
        if (err) {
          console.log(err);
        } else {
          self.getDocsKeys();
          Backend.sendUploadImage(self.state.idCliente, newKey, data.Location,response => console.log(response))
        }
      })
    }
  
     
  }

  getrevertName(name){
    switch (name){
      case 'domicilioImg': return 'Domicilio'
      case 'estadocuentaImg': return 'Estado cuenta'
      case 'pasaporteImg': return 'Pasaporte'
      case 'ine1Img': return 'Ine 1'
      case 'ine2Img': return 'Ine 2'
      default: return 'Other'
    }
  }

  _handleSubmit(e) {
      var newImageDocuments = new FormData();
      newImageDocuments.append('UploadedImage', this.state.file);
      Backend.envioImagen(1, 'GNT LITE', this.state.documentType, this.state.idCliente, 'C', newImageDocuments, response => {
        this.handleHide();
        this.getImageStatus();
        this.setState({
          imagePreviewUrl: '',
        });
      });


    // e.preventDefault();
    // TODO: do something with -> this.state.file
    // console.log('handle uploading-', this.state.file);
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file)
  }

  sendContractToUser(){
    Backend.contractHandler(this.state.idCliente,'E', response => {
      if(response == 'Enviado') this.showSuccess('An email has been sent to client to resign the contract.')
      else if(response == 'Solicitud ya enviada') this.showWarning('The mail had already been sent previously to the client.')
      else this.showError(response)
    })
  }

  deleteUserContract(){
    Backend.contractHandler(this.state.idCliente,'VRE', response => {
      if(response == 'Documento rechazado') this.showSuccess('The contract has been deleted correctly')
      else this.showError(response)
      this.setState({deletingModal:false, contratoImg:LoadImage})
    })
  }

  getData(origin, info,posicion){
    if(origin == 'shareholder'){
      shareholdersDocuments = []
      shareholdersDocumentsExtention = []
      var lista = Object.keys(info).filter((key)=> (key.endsWith('Img') == 1))
      var lista2 = Object.keys(info).filter((key)=> (key.endsWith('ImgExtention') == 1))
      let actualshareholInfo = info
      actualshareholInfo.posicion = (posicion + 1)
      let actualShareNum = posicion

      for (let i = 0; i < lista.length; i++) {
        shareholdersDocuments.push(actualshareholInfo[lista[i]])
      }
      for (let i = 0; i < lista2.length; i++) {
        shareholdersDocumentsExtention.push(actualshareholInfo[lista2[i]])
      }
      this.setState({actualshareholInfo, actualShareNum})
    }

    else {
      directorsDocuments = []
      directorsDocumentsExtention = []
      var lista = Object.keys(info).filter((key)=> (key.endsWith('Img') == 1))
      var lista2 = Object.keys(info).filter((key)=> (key.endsWith('ImgExtention') == 1))
      let actualdirectorInfo = info
      actualdirectorInfo.posicion = (posicion + 1)
      let actualDirectNum = posicion
      for (let i = 0; i < lista.length; i++) {
        directorsDocuments.push(actualdirectorInfo[lista[i]])
      }
      for (let i = 0; i < lista2.length; i++) {
        directorsDocumentsExtention.push(actualdirectorInfo[lista2[i]])
      }
      this.setState({actualdirectorInfo, actualDirectNum})
    }
  }

  getDocName(doc){
    switch(doc){
      case 'domicilioImg': return 'Address Info'
      case 'estadocuentaImg': return 'Bank Info'
      case 'pasaporteImg': return 'Passport'
      case 'ine1Img': return 'ID Front'
      case 'ine2Img': return 'ID Back'
      default: return 'Other'
    }
  }

  renderUserDocs(origin){
    let items = []
    if(origin == 'shareholder'){
      var lista = Object.keys(this.state.actualshareholInfo).filter((key)=> (key.endsWith('Img') == 1))
      for (let i = 0; i < lista.length; i++) {
        if(this.state.actualshareholInfo.hasOwnProperty(lista[i]))
          items.push(
            <Col md={6} lg={4} className="container-line-break">
              <div className="label-check ">
                <label className='text-title-documents '>{this.getDocName(lista[i])}</label>
                <FormControl className="Form-check " type="checkbox"  checked={this.state.actualshareholInfo[lista[i]+'Estatus']} onChange={()=>this.CheckShareDirDoc(this.state.actualshareholInfo[lista[i]+'Estatus'],this.state.actualshareholInfo[lista[i]],lista[i], 'shareholders',this.state.actualshareholInfo.posicion) }/>
                <label className="label-control" for={"file-control3"+i} >Update</label>
                <FormControl type="file" id={"file-control3"+i} className="file-control" onChange={(e)=>{this.UploadImageShareDic(e.target.files[0],lista[i],'shareholders',this.state.actualshareholInfo.posicion)}}/>
              </div>
              <div className="container-front-image" onClick={() => this.openModal2(i)}>
                {
                  (this.state.showLoad)
                    ? (<div className="spinner-image-document"><Spinner color="#fff" size={150} speed={1} animating={this.state.showLoad} /></div>)
                    : this.state.actualshareholInfo[lista[i]+'Extention'] != 'pdf'
                      ? (<img src={this.state.actualshareholInfo[lista[i]]} className="image-documents" />)
                      : (<Button className={"documentStyleTwo"} style={{backgroundColor:'transparent', border: '1px solid transparent' }} >
                          <iframe src={this.state.actualshareholInfo[lista[i]]} className={"documentStyleTwo"} style={{border: '1px solid transparent', pointerEvents:'none', width:'100%', height:'310px'}} ></iframe>
                        </Button>)
                }
              </div>
              
              {/*<Button onClick={()=>{this.searchImage(this.state.idCliente, 'img1', 'INE1')}} className="button-search-client">Show</Button>*/}
            </Col>
          )
      }
    }
    else {
      var lista = Object.keys(this.state.actualdirectorInfo).filter((key)=> (key.endsWith('Img') == 1))
      for (let i = 0; i < lista.length; i++) {
        if(this.state.actualdirectorInfo.hasOwnProperty(lista[i]))
          items.push(
            <Col md={6} lg={4} className="container-line-break">
              <div className="label-check ">
                <label className='text-title-documents '>{this.getDocName(lista[i])}</label>
                <FormControl className="Form-check " type="checkbox"  checked={this.state.actualdirectorInfo[lista[i]+'Estatus']} onChange={()=>this.CheckShareDirDoc(this.state.actualdirectorInfo[lista[i]+'Estatus'],this.state.actualdirectorInfo[lista[i]],lista[i], 'directors',this.state.actualdirectorInfo.posicion) }/>
                <label className="label-control" for={"file-control4"+i}>Update</label>
                <FormControl type="file" id={"file-control4"+i} className="file-control" onChange={(e)=>{this.UploadImageShareDic(e.target.files[0],lista[i],'directors',this.state.actualdirectorInfo.posicion)}}/>
              </div>
              <div className="container-front-image" onClick={() => this.openModal2(i)}>
                {
                  (this.state.showLoad)
                    ? (<div className="spinner-image-document"><Spinner color="#fff" size={150} speed={1} animating={this.state.showLoad} /></div>)
                    : this.state.actualdirectorInfo[lista[i]+'Extention'] != 'pdf'
                      ? (<img src={this.state.actualdirectorInfo[lista[i]]} className="image-documents" />)
                      : (<Button className={"documentStyleTwo"} style={{backgroundColor:'transparent', border: '1px solid transparent' }} >
                          <iframe src={this.state.actualdirectorInfo[lista[i]]} className={"documentStyleTwo"} style={{border: '1px solid transparent', pointerEvents:'none', width:'100%', height:'310px'}} ></iframe>
                        </Button>)
                }
              </div>
              
              {/*<Button onClick={()=>{this.searchImage(this.state.idCliente, 'img1', 'INE1')}} className="button-search-client">Show</Button>*/}
            </Col>
          )
      }
    }
    return items
  }

  render() {
    const imagesDocuments = [this.state.contratoImg, this.state.ine1Img, this.state.ine2Img,  this.state.pasaporteImg, this.state.estadocuentaImg, this.state.domicilioImg];
    const imagesDocumentsExtention = [this.state.contratoImgExtention, this.state.ine1ImgExtention, this.state.ine2ImgExtention,  this.state.pasaporteImgExtention, this.state.estadocuentaImgExtention, this.state.domicilioImgExtention];
    const companyDocuments = [this.state.contratoImg, this.state.compDocImg]
    const companyDocumentsExtention = [this.state.contratoImgExtention, this.state.compDocImgExtention]
    let {imagePreviewUrl} = this.state;
    let _imagePreview = null;
    if (imagePreviewUrl) {
      _imagePreview = (<img src={imagePreviewUrl} />);
    } else {
      _imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
    }
    return (
      <div
        style={{
          marginLeft: 64,
          padding: '20px 20px 0 20px',
          width: 'calc(100vw - 64px)',
          position: 'absolute',
          zIndex: -100,
          opacity: (this.props.expanded) ? .5 : 1
        }}
      >

        <div className="container-principal">

          {
            this.state.origin != 1
              /* Client / ib */
            ?
            <>
              <Col md={12} className="shadow-container-documents">
                <div className="second-container-documents">
                  <div className="sub-second-container">
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel className='text-title-documents'>Name</ControlLabel>
                          <FormControl type="text" className='input-documents' disabled="true" value={this.state.nameCustomer} onChange={(nameCustomer) => { this.setState({ nameCustomer: nameCustomer.target.value }) }} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel className='text-title-documents'>Last Name</ControlLabel>
                          <FormControl type="text" className='input-documents' disabled="true" value={this.state.lastaNameCustomer} onChange={(lastaNameCustomer) => { this.setState({ lastaNameCustomer: lastaNameCustomer.target.value }) }} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel className='text-title-documents'>Country</ControlLabel>
                          <FormControl type="text" className='input-documents' disabled="true" value={this.state.countryCustomer} onChange={(countryCustomer) => { this.setState({ countryCustomer: countryCustomer.target.value }) }} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel className='text-title-documents'>Address</ControlLabel>
                          <FormControl type="text" className='input-documents' disabled="true" value={this.state.addressCustomer} onChange={(addressCustomer) => { this.setState({ addressCustomer: addressCustomer.target.value }) }} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel className='text-title-documents'>Phone</ControlLabel>
                          <FormControl type="text" className='input-documents' disabled="true" value={this.state.phoneCustomer} onChange={(phoneCustomer) => { this.setState({ phoneCustomer: phoneCustomer.target.value }) }} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel className='text-title-documents'>Email</ControlLabel>
                          <FormControl type="text" className='input-documents' disabled="true" value={this.state.emailCustomer} onChange={(emailCustomer) => { this.setState({ emailCustomer: emailCustomer.target.value }) }} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={3} className="container-line-break">
                        <div className="label-check ">
                          <label className='text-title-documents '>Contract</label>
                          {(this.state.contratoImg != LoadImage)
                            ?
                              <>
                                <FormControl className="Form-check " type="checkbox"  checked={this.state.img0_VI} onChange={()=>this.CheckContract(this.state.img0_VI)}/>
                                <Button onClick={()=>this.setState({deletingModal:true})}>Delete</Button>
                              </>
                            :
                              <>
                                <FormControl className="Form-check " type="checkbox" disabled />
                                <Button onClick={()=>this.sendContractToUser()}>Send Mail</Button>
                              </>
                          }
                        </div>
                        
                        
                        <div className="container-front-image" onClick={() => this.openModal(0)}>
                          {
                            (this.state.showLoad)
                              ? (<div className="spinner-image-document"><Spinner color="#fff" size={150} speed={1} animating={this.state.showLoad} /></div>)
                              : (<Button className={"documentStyleTwo"} style={{backgroundColor:'transparent', border: '1px solid transparent' }} >
                                    <iframe src={this.state.contratoImg} className={"documentStyleTwo"} style={{border: '1px solid transparent', pointerEvents:'none', width:'100%', height:'310px'}} ></iframe>
                                </Button>
                              )
                          }
                        </div>
                        
                        
                      </Col>
                      <Col md={6} lg={3} className="container-line-break">
                        <div className="label-check ">
                          <label className='text-title-documents '>ID Front</label>
                          {(this.state.ine1Img != LoadImage )
                            ?(<FormControl className="Form-check " type="checkbox"  checked={this.state.img1_VI} onChange={()=>this.CheckDoc(this.state.img1_VI,1,'Ine 1') }/>)
                            :(<FormControl className="Form-check " type="checkbox"  disabled/>)
                          }
    
                          <label className="label-control" for="file-control1">Update</label>
                          <FormControl type="file" id="file-control1" className="file-control" onChange={(e)=>{this.UploadImage(e.target.files[0],'Ine 1')}}/>
                        </div>
                        <div className="container-front-image" onClick={() => this.openModal(1)}>
                          {
                            (this.state.showLoad)
                              ? (<div className="spinner-image-document"><Spinner color="#fff" size={150} speed={1} animating={this.state.showLoad} /></div>)
                              : this.state.ine1ImgExtention != 'pdf'
                                ? (<img src={this.state.ine1Img} className="image-documents" />)
                                : (<Button className={"documentStyleTwo"} style={{backgroundColor:'transparent', border: '1px solid transparent' }} >
                                    <iframe src={this.state.ine1Img} className={"documentStyleTwo"} style={{border: '1px solid transparent', pointerEvents:'none', width:'100%', height:'310px'}} ></iframe>
                                  </Button>)
                          }
                        </div>
                        
                        {/*<Button onClick={()=>{this.searchImage(this.state.idCliente, 'img1', 'INE1')}} className="button-search-client">Show</Button>*/}
                      </Col>
                      <Col md={6} lg={3} className="container-line-break">
                        <div className="label-check ">
                          <label className='text-title-documents '>ID Back</label>
                          {(this.state.ine2Img != LoadImage )
                            ?<FormControl className="Form-check " type="checkbox"  checked={this.state.img2_VI} onChange={()=>this.CheckDoc(this.state.img2_VI,2,'Ine 2')}/>
                            :<FormControl className="Form-check " type="checkbox"  disabled/>
                          }
    
                          <label className="label-control" for="file-control2">Update</label>
                          <FormControl type="file" id="file-control2" className="file-control" onChange={(e)=>{this.UploadImage(e.target.files[0],'Ine 2')}}/>
                        </div>
                        <div className="container-front-image" onClick={() => this.openModal(2)}>
                          {
                            (this.state.showLoad)
                              ? (<div className="spinner-image-document"><Spinner color="#fff" size={150} speed={1} animating={this.state.showLoad} /></div>)
                              : this.state.ine2ImgExtention != 'pdf'
                                ? (<img src={this.state.ine2Img} className="image-documents" />)
                                : (<Button className={"documentStyleTwo"} style={{backgroundColor:'transparent', border: '1px solid transparent' }} >
                                    <iframe src={this.state.ine2Img} className={"documentStyleTwo"} style={{border: '1px solid transparent', pointerEvents:'none', width:'100%', height:'310px'}} ></iframe>
                                  </Button>)
                          }
    
                        </div>
                        {/*<Button onClick={()=>{this.searchImage(this.state.idCliente, 'img2', 'INE2')}} className="button-search-client">Show</Button>*/}
                      </Col>
                      <Col md={6} lg={3} className="container-line-break">
                        <div className="label-check ">
                          <label className='text-title-documents '>Passport</label>
                          {(this.state.pasaporteImg != LoadImage )
                            ?<FormControl className="Form-check " type="checkbox" checked={this.state.img3_VI} onChange={()=>this.CheckDoc(this.state.img3_VI,3,'Pasaporte')}/>
                            :<FormControl className="Form-check " type="checkbox" disabled/>
                          }
    
                        
                          <label className="label-control" for="file-control3">Update</label>
                          <FormControl type="file" id="file-control3" className="file-control" onChange={(e)=>{this.UploadImage(e.target.files[0],'Pasaporte')}}/>
                        </div>
                        <div className="container-front-image" onClick={() => this.openModal(3)}>
                          {
                            (this.state.showLoad)
                              ? (<div className="spinner-image-document"><Spinner color="#fff" size={150} speed={1} animating={this.state.showLoad} /></div>)
                              : this.state.pasaporteImgExtention != 'pdf'
                              ?(<img src={this.state.pasaporteImg} className="image-documents" />)
                              :(  <Button className={"documentStyleTwo"} style={{backgroundColor:'transparent', border: '1px solid transparent' }} >
                                    <iframe src={this.state.pasaporteImg} className={"documentStyleTwo"} style={{border: '1px solid transparent', pointerEvents:'none', width:'100%', height:'310px'}} ></iframe>
                                  </Button>)
                          }
                        </div>
                        
                      </Col>
                      <Col md={6} lg={3} className="container-line-break">
                        <div className="label-check ">
                          <label className='text-title-documents '>Bank Info</label>
                          {(this.state.estadocuentaImg != LoadImage)
                            ? <FormControl className="Form-check " type="checkbox"  checked={this.state.img4_VI} onChange={()=>this.CheckDoc(this.state.img4_VI,4,'Estado cuenta')}/>
                            : <FormControl className="Form-check " type="checkbox" disabled />
                          }
    
                          <label className="label-control" for="file-control4">Update</label>
                          <FormControl type="file" id="file-control4" className="file-control" onChange={(e)=>{this.UploadImage(e.target.files[0],'Estado cuenta')}}/>
                        </div>
                        
                        <div className="container-front-image" onClick={() => this.openModal(4)}>
                          {
                            (this.state.showLoad)
                              ? (<div className="spinner-image-document"><Spinner color="#fff" size={150} speed={1} animating={this.state.showLoad} /></div>)
                              : this.state.estadocuentaImgExtention != 'pdf'
                                ? (<img src={this.state.estadocuentaImg} className="image-documents" />)
                                : (<Button className={"documentStyleTwo"} style={{backgroundColor:'transparent', border: '1px solid transparent' }} >
                                    <iframe src={this.state.estadocuentaImg} className={"documentStyleTwo"} style={{border: '1px solid transparent', pointerEvents:'none', width:'100%', height:'310px'}} ></iframe>
                                  </Button>)
                          }
                        </div>
                        
                        
                      </Col>
                      <Col md={6} lg={3} className="container-line-break">
                        <div className="label-check ">
                          <label className='text-title-documents '>Address Info</label>
                          {(this.state.domicilioImg != LoadImage)
                            ? <FormControl className="Form-check " type="checkbox"  checked={this.state.img5_VI} onChange={()=>this.CheckDoc(this.state.img5_VI,5,'Domicilio')}/>
                            : <FormControl className="Form-check " type="checkbox" disabled />
                          }
    
                          <label className="label-control" for="file-control5">Update</label>
                          <FormControl type="file" id="file-control5" className="file-control" onChange={(e)=>{this.UploadImage(e.target.files[0],'Domicilio')}}/>
                        </div>
                        
                        <div className="container-front-image" onClick={() => this.openModal(5)}>
                          {
                            (this.state.showLoad)
                              ? (<div className="spinner-image-document"><Spinner color="#fff" size={150} speed={1} animating={this.state.showLoad} /></div>)
                              : this.state.domicilioImgExtention != 'pdf'
                                ? (<img src={this.state.domicilioImg} className="image-documents" />)
                                : (<Button className={"documentStyleTwo"} style={{backgroundColor:'transparent', border: '1px solid transparent' }} >
                                    <iframe src={this.state.domicilioImg} className={"documentStyleTwo"} style={{border: '1px solid transparent', pointerEvents:'none', width:'100%', height:'310px'}} ></iframe>
                                  </Button>)
                          }
                        </div>
                        
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </>
            /* Company */
            :
            <>
              <Col md={12} className="shadow-container-documents">
                <div className="second-container-documents">
                  {/* Info de Empresa */}
                  <div className="sub-second-container">
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel className='text-title-documents'>Company Name</ControlLabel>
                          <FormControl type="text" className='input-documents' disabled="true" value={this.state.nameCustomer} onChange={(nameCustomer) => { this.setState({ nameCustomer: nameCustomer.target.value }) }} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel className='text-title-documents'>Company Website</ControlLabel>
                          <FormControl type="text" className='input-documents' disabled="true" value={this.state.lastaNameCustomer} onChange={(lastaNameCustomer) => { this.setState({ lastaNameCustomer: lastaNameCustomer.target.value }) }} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel className='text-title-documents'>Country</ControlLabel>
                          <FormControl type="text" className='input-documents' disabled="true" value={this.state.countryCustomer} onChange={(countryCustomer) => { this.setState({ countryCustomer: countryCustomer.target.value }) }} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel className='text-title-documents'>Address</ControlLabel>
                          <FormControl type="text" className='input-documents' disabled="true" value={this.state.addressCustomer} onChange={(addressCustomer) => { this.setState({ addressCustomer: addressCustomer.target.value }) }} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel className='text-title-documents'>Phone</ControlLabel>
                          <FormControl type="text" className='input-documents' disabled="true" value={this.state.phoneCustomer} onChange={(phoneCustomer) => { this.setState({ phoneCustomer: phoneCustomer.target.value }) }} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel className='text-title-documents'>Email</ControlLabel>
                          <FormControl type="text" className='input-documents' disabled="true" value={this.state.emailCustomer} onChange={(emailCustomer) => { this.setState({ emailCustomer: emailCustomer.target.value }) }} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={4} className="container-line-break">
                        <div className="label-check ">
                          <label className='text-title-documents '>Contract</label>
                          {(this.state.contratoImg != LoadImage)
                            ?
                              <>
                                <FormControl className="Form-check " type="checkbox"  checked={this.state.img0_VI} onChange={()=>this.CheckContract(this.state.img0_VI)}/>
                                <Button onClick={()=>this.setState({deletingModal:true})}>Delete</Button>
                              </>
                            :
                              <>
                                <FormControl className="Form-check " type="checkbox" disabled />
                                <Button onClick={()=>this.sendContractToUser()}>Send Mail</Button>
                              </>
                          }
                        </div>
                        
                        
                        <div className="container-front-image" onClick={() => this.openModal(0)}>
                          {
                            (this.state.showLoad)
                              ? (<div className="spinner-image-document"><Spinner color="#fff" size={150} speed={1} animating={this.state.showLoad} /></div>)
                              : (<Button className={"documentStyleTwo"} style={{backgroundColor:'transparent', border: '1px solid transparent' }} >
                                    <iframe src={this.state.contratoImg} className={"documentStyleTwo"} style={{border: '1px solid transparent', pointerEvents:'none', width:'100%', height:'310px'}} ></iframe>
                                </Button>
                              )
                          }
                        </div>
                        
                        
                      </Col>
                      <Col md={6} lg={4} className="container-line-break">
                        <div className="label-check ">
                          <label className='text-title-documents '>{this.state.companyDoc}</label>
                          {(this.state.compDocImg != LoadImage )
                            ?(<FormControl className="Form-check " type="checkbox"  checked={this.state.img6_VI} onChange={()=>this.CheckDoc(this.state.img6_VI,6,this.state.companyDocName) }/>)
                            :(<FormControl className="Form-check " type="checkbox"  disabled/>)
                          }
    
                          <label className="label-control" for="file-control1">Update</label>
                          <FormControl type="file" id="file-control1" className="file-control" onChange={(e)=>{this.UploadImage(e.target.files[0],this.state.companyDocName)}}/>
                        </div>
                        <div className="container-front-image" onClick={() => this.openModal(1)}>
                          {
                            (this.state.showLoad)
                              ? (<div className="spinner-image-document"><Spinner color="#fff" size={150} speed={1} animating={this.state.showLoad} /></div>)
                              : this.state.compDocImgExtention != 'pdf'
                                ? (<img src={this.state.compDocImg} className="image-documents" />)
                                : (<Button className={"documentStyleTwo"} style={{backgroundColor:'transparent', border: '1px solid transparent' }} >
                                    <iframe src={this.state.compDocImg} className={"documentStyleTwo"} style={{border: '1px solid transparent', pointerEvents:'none', width:'100%', height:'310px'}} ></iframe>
                                  </Button>)
                          }
                        </div>
                        
                        {/*<Button onClick={()=>{this.searchImage(this.state.idCliente, 'img1', 'INE1')}} className="button-search-client">Show</Button>*/}
                      </Col>
                    </Row>
                  </div>
                  {/* Tabs Shareholders & Directores */}
                  <div className="sub-second-container">
                    <Tabs defaultActiveKey="directors" onSelect={(e)=>this.setState({actualTab:e})} id="uncontrolled-tab-example" className="mb-3">
                      {/* Tab Directores */}
                      <Tab eventKey="directors"    title="Directors">
                        <div style={{padding:15}}>
                          <div className=""> 
                            <label>Actual Director: </label> <br></br>
                            <DropdownButton title={this.state.directorValue} className="SelectMenu">
                              {this.state.directorInfo.map((item,i) =>{
                                return (
                                  <MenuItem onClick={()=>this.setState({directorValue: (item.nombre + ' ' + item.apellidos)},()=>this.getData('director',item, i))}>{item.nombre} {item.apellidos}</MenuItem>
                                )
                              })}
                            </DropdownButton>
                          </div>
                          <br></br>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>Name</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualdirectorInfo.nombre} />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>Last Name</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualdirectorInfo.apellidos} />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>Birthdate</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualdirectorInfo.birthdayDate} />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>Address</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualdirectorInfo.address} />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                            <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>State</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualdirectorInfo.state} />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>City</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualdirectorInfo.city} />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>Zip Code</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualdirectorInfo.zipcode} />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>Country</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualdirectorInfo.country} />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              {this.renderUserDocs('director')}
                            </Row>
                        </div>
                      </Tab>
                      {/* Tab Shareholders */}
                      <Tab eventKey="shareholders" title="Shareholders">
                        <div style={{padding:15}}>
                          <div className=""> 
                            <label>Actual Shareholder: </label> <br></br>
                            <DropdownButton title={this.state.shareholderValue} className="SelectMenu">
                              {this.state.shareholInfo.map((item, i) =>{
                                return (
                                  <MenuItem onClick={()=>this.setState({shareholderValue: (item.nombre + ' ' + item.apellidos)},()=>this.getData('shareholder',item, i))}>{item.nombre} {item.apellidos}</MenuItem>
                                )
                              })}
                              
                            </DropdownButton>
                          </div>
                          <br></br>
                          <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>Name</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualshareholInfo.nombre} />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>Last Name</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualshareholInfo.apellidos} />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>Birthdate</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualshareholInfo.birthdayDate} />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>Address</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualshareholInfo.address} />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                            <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>State</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualshareholInfo.state} />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>City</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualshareholInfo.city} />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>Zip Code</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualshareholInfo.zipcode} />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className='text-title-documents'>Country</ControlLabel>
                                  <FormControl type="text" className='input-documents' disabled="true" value={this.state.actualshareholInfo.country} />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              {this.renderUserDocs('shareholder')}
                            </Row>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </Col>
            </>
          }
        </div>
        {
          (this.state.origin != 1 ? imagesDocuments[this.state.currentIndex] :companyDocuments[this.state.currentIndex])
          && 
          <GalleryModal
            closeModal={this.closeModal}
            findPrev={this.findPrev}
            findNext={this.findNext}
            origen={1}
            hasPrev={this.state.currentIndex > 0}
            hasNext={ this.state.origin != 1 ? (this.state.currentIndex  < imagesDocuments.length) :(this.state.currentIndex  < companyDocuments.length)}
            index={this.state.currentIndex}
            src={ this.state.origin != 1 ? imagesDocuments[this.state.currentIndex] :companyDocuments[this.state.currentIndex]}
            docType={ this.state.origin != 1 ? imagesDocumentsExtention[this.state.currentIndex] :companyDocumentsExtention[this.state.currentIndex]}
          />
        }
        

        <GalleryModal
          closeModal={this.closeModal2}
          findPrev={this.findPrev2}
          findNext={this.findNext2}
          hasPrev={this.state.currentIndex2 > 0}
          hasNext={ this.state.actualTab == 'directors' ? (this.state.currentIndex2  < directorsDocuments.length) :(this.state.currentIndex2  < shareholdersDocuments.length)}
          index={this.state.currentIndex2}
          src={ this.state.actualTab == 'directors' ? directorsDocuments[this.state.currentIndex2] :shareholdersDocuments[this.state.currentIndex2]}
          docType={ this.state.actualTab == 'directors' ? directorsDocumentsExtention[this.state.currentIndex2] :shareholdersDocumentsExtention[this.state.currentIndex2]}
        />

      <Modal show={this.state.deletingModal} onHide={()=>this.setState({deletingModal:false})}>
      <Modal.Header className='tabSubtitle' style={{textAlign:'center', display:'block', fontSize:'larger'}}>
          Delete Contract
        </Modal.Header>
          <Modal.Body>
            <div>
              <p className="DeleteText">Delete a contract is permanent, it only proceeds if it is necessary for the user to re-sign.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='new-role-delete' onClick={()=>this.deleteUserContract()}>Delete</button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
} export default ClientRequests;
