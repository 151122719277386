
import React, {Component} from 'react';
import { Col,Row,Modal, Button, FormGroup, ControlLabel, FormControl, MenuItem, DropdownButton} from 'react-bootstrap';
import { store } from 'react-notifications-component';
import { Spinner } from 'react-activity';
import Backend from '../../../backend';



class EmailAds extends Component{
  constructor(props){
    super(props)
    this.state = {
        emailSubject:'',
        emailMessage:'',
        spinner:false,
        dropdownValue:'All Clients',
        filtro:'T',
    }
  }
    sendMailV(){
        const self = this;
        self.setState({spinner:true})
        if(self.state.emailSubject.trim() !== '' && self.state.emailMessage.trim() !== ''){
          var message = self.state.emailMessage.replace(/\n/g, '<br></br>')
          Backend.SendAdsMail(self.state.emailSubject,message,self.state.filtro,response=>{
            if(response === ' Correo Enviado') self.showSuccess("An email has been sent")
            else self.showError('An error has ocurred')
          })
          self.setState({show:false,spinner:false,emailSubject:'', emailMessage:'', spinner:false, dropdownValue:'All Clients', filtro:'T',})
        }
        else {
          self.showError('Please fill up the Subject and message')
          self.setState({show:false,spinner:false,spinner:false,})
        }
    }

    showSuccess(success){
      store.addNotification({
        title: "Success!",
        message: success,
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    } 

    showError(error){
      store.addNotification({
        title: "Error",
        message: error,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }

    render(){
        return(
          <div
          style={{
            marginLeft: 64,
            padding: '20px 20px 0 20px',
            width: 'calc(100vw - 64px)',
            position: 'absolute',
            zIndex: -100,
            opacity: (this.props.expanded) ? .5 : 1
          }}
          >

        <div className="container-principal">
          <Col md={12} className="shadow-container-documents">
            <div className='first-container-documents'>
              <div className='header-documents'>
                Send Ads by Email
              </div>
            </div>
            <div className="container-table-results" >
              <Row >
                <Col md={10}>
                  <FormGroup>
                      <ControlLabel className='text-title-documents' >Email Subject</ControlLabel>
                      <FormControl type="text" className='input-documents' value={this.state.emailSubject} onChange={(emailSubject) => { this.setState({ emailSubject: emailSubject.target.value }) }} />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <div className="DropMenu"> 
                    <label>Send ad to: </label> <br></br>
                    <DropdownButton title={this.state.dropdownValue} className="SelectMenu">
                      <MenuItem onClick={()=>this.setState({filtro:'T',dropdownValue:'All Clients'})}>All Clients</MenuItem>
                      <MenuItem onClick={()=>this.setState({filtro:'B',dropdownValue:'Zero Balance Clients'})}>Zero Balance Clients</MenuItem>
                      <MenuItem onClick={()=>this.setState({filtro:'IB',dropdownValue:'Ibs'})}>Ibs</MenuItem>
                      <MenuItem onClick={()=>this.setState({filtro:'TS',dropdownValue:'Test Email'})}>Test Email</MenuItem>
                    </DropdownButton>
                  </div>
                </Col>
              </Row>
                <div className="container-table-results tableSpace" >
                    <div className='formatDiv'  >
                        <div className='headerDiv' >
                            <img src="https://i.imgur.com/Fatqqaz.png"  title="My Broker FX"  className="CToWUd mailImgStyle"/>
                        </div>
                        <div className='emailBody' >
                            <p className='emailTextName' >Hello Client: </p>
                            <FormGroup controlId="formControlsTextarea">
                                <FormControl componentClass="textarea" className='myTextAreaStyle emailContactTxt'  placeholder="Write here the message for the clients..." onChange={(e) => { this.setState({ emailMessage: e.target.value }) }} value={this.state.emailMessage} />
                            </FormGroup>
                            <p className='emailContactTxt emailTextMBFX' >Kind Regards, </p>
                            <p className='emailContactTxt emailTextMBFX' >My Broker FX Team</p>
                        </div>
                        <div className='emailFooterDiv'>
                            <p className='emailContactTxt' style={{fontStyle:'italic'}}  >Contact us <strong>support@mybrokerfx.com</strong></p>
                        </div>
                    </div>
                    <div className='body-documents'>
                        <Row>
                          <Col md={12} style={{textAlign:'center'}}>
                            <Button  className='button-log-out' onClick={()=>this.setState({show:true})} >Send Massive Ad</Button>
                          </Col>
                        </Row>
                    </div>
                </div>
            </div>
          </Col>
        </div>
        <Modal size="sm" show={this.state.show} onHide={()=>this.setState({show: !this.state.show})}>

          <Modal.Header closeButton>
            <Modal.Title>Confirm massive Ad</Modal.Title>
          </Modal.Header>

        <Modal.Body className="Modal-Body">Are you sure you want to send this message to {this.state.dropdownValue} ?</Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={()=>this.setState({show: !this.state.show})}>
              Cancel
            </Button>
            <Button variant="primary" onClick={()=>this.sendMailV()} disabled={this.state.spinner}>
                { this.state.spinner
                  ? <Spinner color="#333" size={10} speed={1} animating={this.state.spinner} disabled />
                  : "Confirm"
                }
            </Button>
          </Modal.Footer>

        </Modal>
      </div>
    )
  }
}export default EmailAds;