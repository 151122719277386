import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import TradingAccounts from './tradingAccounts';
import EmailAds from './adsForClients';
import ClientRequests from './clientRequests';
import Withdrawals from './withdrawals/index.js';
import Settings from './settings';

import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component'
import SendMail from './SendMail';



class IndexDashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      active:'trading',
      expanded:false,
      client:{}
    }
    this._clientPicked = this._clientPicked.bind(this)
  }

  _changeOption = (newState) => {
    this.setState({active:newState})
  }

  _clientPicked = (client) => {
    this.setState({active:'clients', client:client})
  }

  _onToggle = (newValue) => {
    this.setState({expanded:newValue})
  }

  _renderContent = () => {
    switch (this.state.active) {
      case 'trading':
        return <TradingAccounts _clientPicked={this._clientPicked} expanded={this.state.expanded}/>
        break;
      case 'clients':
        return <ClientRequests client={this.state.client} expanded={this.state.expanded}/>
        break;
      case 'withdrawals':
        return <Withdrawals expanded={this.state.expanded}/>
        break;
      case 'unverified':
        return <SendMail expanded={this.state.expanded}/>
        break;
      case 'sendMail':
        return <EmailAds expanded={this.state.expanded}/>
        break;
      default:
        return <Settings expanded={this.state.expanded} data={this.props.data}/>
        break;
    }
  }

  render() {
    return (
      <div>
        <div
            style={{
                position: 'fixed',
                height: 'calc(100vh)'
            }}
        >
        <SideNav onSelect={this._changeOption} onToggle={this._onToggle} className={'sideNav'}>
            <SideNav.Toggle />
            <SideNav.Nav selected={this.state.active}>
                <NavItem eventKey="trading">
                    <NavIcon>
                        <span className="glyphicon glyphicon-blackboard" aria-hidden="true"></span>
                    </NavIcon>
                    <NavText>
                        Trading accounts
                    </NavText>
                </NavItem>
                <NavItem eventKey="clients">
                    <NavIcon>
                        <span className="glyphicon glyphicon-user" aria-hidden="true"></span>
                    </NavIcon>
                    <NavText>
                        Client requests
                    </NavText>
                </NavItem>
                <NavItem eventKey="withdrawals">
                    <NavIcon>
                        <span className="glyphicon glyphicon-usd" aria-hidden="true"></span>
                    </NavIcon>
                    <NavText>
                        Withdrawals
                    </NavText>
                </NavItem>
                <NavItem eventKey="unverified">
                    <NavIcon>
                        <span className="glyphicon glyphicon-inbox" aria-hidden="true"></span>
                    </NavIcon>
                    <NavText>
                      Unverified clients
                    </NavText>
                </NavItem>
                <NavItem eventKey="sendMail">
                    <NavIcon>
                        <span className="glyphicon glyphicon-envelope" aria-hidden="true"></span>
                    </NavIcon>
                    <NavText>
                      Email Ads 
                    </NavText>
                </NavItem>
                <NavItem eventKey="settings">
                    <NavIcon>
                        <span className="glyphicon glyphicon-cog" aria-hidden="true"></span>
                    </NavIcon>
                    <NavText>
                        Settings
                    </NavText>
                </NavItem>
            </SideNav.Nav>
        </SideNav>
        </div>
        {this._renderContent()}
        <ReactNotification />
      </div>
    );
  }
}

export default IndexDashboard;
