import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import LogIn from './components/logIn';
import Home from './components/dashboard';

class MainRouter extends Component{
  render(){
    return(
        <Router>
          <div>
            <Route exact={true} path="/" render={(props)=>(
                <LogIn {...props}/>
              )}/>

            <Route path="/home" render={(props)=>(
              localStorage.getItem('session')?
                (<Home data={props} />)
                :
                (<Redirect to ={{pathname: "/"}}/>)
            )}/>

          </div>
      </Router>
    );
  }
}
export default MainRouter;
