import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './router';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase';

const config = {
  apiKey: "AIzaSyAkYOi66z_WrcoRSFBil0HKdk7dmd0dJIg",
  authDomain: "gnt-administrator.firebaseapp.com",
  databaseURL: "https://gnt-administrator.firebaseio.com",
  projectId: "gnt-administrator",
  storageBucket: "gnt-administrator.appspot.com",
  messagingSenderId: "907066770977"
}
firebase.initializeApp(config)

var configP = {
    apiKey: "AIzaSyC8-vwzGLc-JlIREs485biN_0MQuR002gA",
    authDomain: "prices-eadd5.firebaseapp.com",
    databaseURL: "https://prices-eadd5.firebaseio.com",
    projectId: "prices-eadd5",
    storageBucket: "prices-eadd5.appspot.com",
    messagingSenderId: "527338999468"
  };
firebase.initializeApp(configP,'ourPrices');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
