import React, {Component} from 'react';
import './style.css';
import { Col,Row,Tab, Nav, NavItem, Button, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import * as firebase from 'firebase';

class Settings extends Component{
  constructor(props){
    super(props)
    this.state = {
      selected: 'home',
      expanded: false
    }
  }

  _logOut = () => {
    const self = this;
    firebase.auth().signOut().then(function() {
      localStorage.clear();
      self.props.data.history.push("/");
    }).catch(function(error) {
      console.log(error);
    });
  }

  render(){
    return(
      <div
        style={{
          marginLeft: 64,
          padding: '20px 20px 0 20px',
          width: 'calc(100vw - 64px)',
          position: 'absolute',
          zIndex: -100,
          opacity: (this.props.expanded) ? .5 : 1
        }}
        >
        <div className="container-principal">
          <Col md={12} className="shadow-container-documents">
            <div className='first-container-documents'>
              <div className='header-documents'>
                Settings
              </div>
              <div className='body-documents'>
                <Row>
                  <Col md={12} style={{textAlign:'center'}}>
                    <Button className='button-log-out' onClick={this._logOut}>Log Out</Button>
                  </Col>
                </Row>
              </div>
              <div className="footer-documents">
              </div>
            </div>
          </Col>
        </div>
      </div>

    )
  }
}export default Settings;
