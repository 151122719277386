import React, {Component} from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { Col,Row,Table, Button, Modal, MenuItem, DropdownButton } from 'react-bootstrap';
import 'react-notifications-component/dist/theme.css';
import Backend from '../../../backend';

function formatMoney(n,c,d,t){
var n = n,
    c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-$" : "$",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;
   return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
 };

class withdrawals extends Component{
  
  constructor(props){
    super(props)
    this.state = {
      selected: 'home',
      expanded: false,
      listaAllCustomer: [],
      show: false,
      cambio_idIB: "",
      cambio_idSIB: "",
      cambio_operacion: "",
      cambio_ib_ga: "",
      searchFilterText: '',
      client: {idgntadmin_catMethodW:4},
      filtro: 0,
      dropdownValue:'Pending'
    }
  }

  getData(){
    //console.log(this.state.filtro);
    Backend.getmt5Account(this.state.filtro,response => {
      response = JSON.parse(response)
       //console.log(response);
      this.setState({
        listaAllCustomer: response,
      })
    });
  }

  letPagoModal(operacion){
    //console.log(this.state.operacion);
    Backend.letPagos(this.state.client.idgnt_whitelevel_admin_withdrawalctes, operacion,response => {
      //console.log(response);
      response = JSON.parse(response)

      //console.log(response);
      this.setState({
        show: false,
        client: {},
        listaAllCustomer: response,
      })
      this.getData();
    });
  }

  componentWillMount(){
    Backend.getmt5Account(0,response => {
      response = JSON.parse(response)
       //console.log(response);
      this.setState({
        listaAllCustomer: response,
      })
    });
  }

  ModalDetails(client){
    
    let operacion

    if(client.SIB != 0){
      operacion="CBS"
    }else{
      operacion="CBI"
    }
    
    this.setState({
      show: !this.state.show,
      cambio_idIB: client.IB,
      cambio_idSIB: client.SIB,
      cambio_operacion: operacion,
      cambio_ib_ga: client.Gain,
      client:client,
    }) 
  }

  renderUsers(){
    const self = this;
    var users = [];
    this.state.listaAllCustomer.forEach(function (row) {
      users.push(
        <tr> 
          <td>{row.idgnt_whitelevel_admin_withdrawalctes}</td>
          <td>{row.Name}</td>
          <td>{<Moment>{row.fechaEnt}</Moment>}</td>
          <td>{formatMoney(row.Balance)}</td>
          <td>{row.mt5Account}</td>
          <td>{row.amount}</td>
          <td>{row.typeMethod}</td>
          <td>{row.reason}</td>
          <td>{self.state.dropdownValue}</td>
          <td>
            {
              (self.state.filtro == 0) &&
              <Button onClick={()=>self.ModalDetails(row)} className="button-search-filter" >
                View
              </Button>
            }
            </td>
        </tr>
      )
    });
    return users;
  }

  _renderModalContent(){
    console.log(this.state.client)
    switch (this.state.client.idgnt_whitelevel_admin_catmethodw) {
      case 4:
        return(
          <div>
            <Row>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Customer name:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.Name}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Email:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.email}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>MT5 account:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.mt5Account}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Address:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.address}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Zip code:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.zipcode}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>City:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.city}</p>
                </div>
              </Col>
            </Row>
            <Row>
            <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>State:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.state}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Bank name:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.bankName}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Intermediary bank:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.interBank != '' ?this.state.client.interBank :'-'}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Bank address:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.bankAddress}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Bank account number:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.accountNumber}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Bank reference/CLABE:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.bankRefCBE}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Swift Bank Code:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.swiftBC != '' ?this.state.client.swiftBC :'-'}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>ABA Code:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.abaCode != '' ?this.state.client.abaCode :'-'}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Reference:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.reference}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Reason:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.reason}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Amount:</strong></p>
                  <p style={{ color: "#404B62" }}>{formatMoney(this.state.client.amount)} {this.state.client.TipoPago}</p>
                </div>
              </Col>
            </Row>
            </div>
        );
        break;
      case 5:
        return(
          <div>
            <Row>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text" ><strong>Customer name:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.Name}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Email:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.email}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>MT5 account:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.mt5Account}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Reason:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.reason}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Amount:</strong></p>
                  <p style={{ color: "#404B62" }}>{formatMoney(this.state.client.amount)} {this.state.client.moneda}</p>
                </div>
              </Col>
            </Row>  
            <Row>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Crypto</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.tipo}</p>
                </div>
              </Col>
              <Col md={8}>
                <div>
                  <p className="catalogo-label-text"><strong>Deposit address:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.depositAddress}</p>
                </div>
              </Col>
            </Row>
          </div>
        );
        break;
      case 6:
        return(
          <div>
            <Row>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Customer name:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.Name}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Email:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.email}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>MT5 account:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.mt5Account}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div>
                  <p className="catalogo-label-text"><strong>Reason:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.reason}</p>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <p className="catalogo-label-text"><strong>Amount:</strong></p>
                  <p style={{ color: "#404B62" }}>{formatMoney(this.state.client.amount)} {this.state.client.moneda}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div>
                  <p className="catalogo-label-text"><strong>Neteller email:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.emailNeteller}</p>
                </div>
              </Col>
            </Row>
          </div>
        );
        break;
      case 7:
        return(
          <div>
            <Row>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>Customer name:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.Name}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text">E<strong>mail:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.email}</p>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <p className="catalogo-label-text"><strong>MT5 account:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.mt5Account}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div>
                  <p className="catalogo-label-text"><strong>Reason:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.reason}</p>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <p className="catalogo-label-text"><strong>Amount:</strong></p>
                  <p style={{ color: "#404B62" }}>{formatMoney(this.state.client.amount)} {this.state.client.moneda}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div>
                  <p className="catalogo-label-text"><strong>Skrill email:</strong></p>
                  <p style={{ color: "#404B62" }}>{this.state.client.emailSkrill}</p>
                </div>
              </Col>
            </Row>
            </div>
        );
        break;
      default:
    }
  }

  render(){
    return(
      <div
        style={{
          marginLeft: 64,
          padding: '20px 20px 0 20px',
          width: 'calc(100vw - 64px)',
          position: 'absolute',
          zIndex: -100,
          opacity: (this.props.expanded) ? .5 : 1
        }}
        >
        <div className="container-principal">
          <Col md={12} className="shadow-container-documents">
            <div className='first-container-documents'>
              <div className='header-documents'>
                Customer withdrawals
              </div>
            </div>
            <div className="container-table-results">
              <div className="DropMenu"> 
                <label>Account type: </label> <br></br>
                <DropdownButton title={this.state.dropdownValue} className="SelectMenu">
                  <MenuItem onClick={()=>this.setState({filtro:0,dropdownValue:'Pending'},()=>this.getData())}>Pending</MenuItem>
                  <MenuItem onClick={()=>this.setState({filtro:1,dropdownValue:'Payed'},()=>this.getData())}>Payed</MenuItem>
                  <MenuItem onClick={()=>this.setState({filtro:2,dropdownValue:'Rejected'},()=>this.getData())}>Rejected</MenuItem>
                </DropdownButton>
              </div>
              <br></br>
              <div className="container-table-results">
              {
               <Table responsive>
               <thead className="head-table-documents">
                 <tr>
                   <th>Id</th>
                   <th>Name</th>
                   <th>Date</th>
                   <th>Actual Balance</th>
                   <th>MT5 Account</th>
                   <th>Withdrawal Amount</th>
                   <th>Type Method</th>
                   <th>Reason</th>
                   <th>Status</th>
                   <th></th>
                 </tr>
               </thead>
               <tbody className="tbody-table-documents">
                 {this.renderUsers()}
               </tbody>
             </Table>
              }
            </div>
            </div>
          </Col>
        </div>

        <Modal size="sm" show={this.state.show} onHide={()=>this.setState({show: !this.state.show})}>
          <Modal.Header closeButton>
          <Modal.Title>Withdrawal Details</Modal.Title>
        </Modal.Header>

          <Modal.Body className="Modal-Body">
          { this._renderModalContent() }
           {/* <table>
              <tr className ="rowTitle ">
                  <td className="row1 ">Customer Name:</td>
                  <td className="row1 ">Email: </td>
                  <td className="row1 col3">MT5 account: </td>
              </tr>
              <tr className="rowInfo">
                <td className="row1 ">{this.state.client.Name} </td>
                <td className="row1 "> {this.state.client.emailNeteller} </td>
                <td className="row1 col3">{this.state.client.mt5Account}</td>
              </tr>
              <br></br>
              <tr className ="rowTitle ">
                <td className="">Reason:</td>
                <td></td>
                <td className=""> Amount: </td>
              </tr>
              <tr className="rowInfo">
                <td className="">{this.state.client.reason}</td>
                <td></td>
              <td className=""> {formatMoney( this.state.client.amount)} {this.state.client.moneda} </td>
              </tr>  
              <br></br>
              <tr className ="rowTitle ">
                <td>Crypto:</td>
                <td> Depossit Adress: </td>
              </tr>  
              <tr className="rowInfo">
                <td>{this.state.client.tipo}</td>
                <td> {this.state.client.depositAddress}</td>
                <br></br>
              </tr>
            </table> */}
          </Modal.Body>
          <Modal.Footer>
            <Button className="PayButton" variant="primary" onClick={(()=> this.letPagoModal("P"))}>
                Pay
              </Button>
            <Button className="RejectButton" variant="secondary" onClick={()=> this.letPagoModal("R")}>
                Reject
            </Button>         
          </Modal.Footer>
        </Modal> 
      </div>
    )
  }

} export default withdrawals;