
import React, {Component} from 'react';
import { Col,Row,Tab, Nav, NavItem, Button, FormGroup, ControlLabel, FormControl,Table} from 'react-bootstrap';
import Backend from '../../../backend';
import { store } from 'react-notifications-component';



class InvalidClients extends Component{
  constructor(props){
    super(props)
    this.state = {
      selected: 'home',
      expanded: false,
      allCustomer:[],
      searchFilterText:''
    }
  }
  componentWillMount(){
    Backend.allInvalidCustomers(response=>{
        response = JSON.parse(response)
        this.setState({
            allCustomer:response,
        })
        console.log(response)
    })
  }

    sendMailV(e){
        let body =JSON.stringify(e)
        // id,email,from,msg,type
        Backend.SendConfirmationMail(e.idgnt_whitelevel_validacion_email,e.emailValidar,e.envio,e.mensaje,e.tipo,response=>{
            if(response === 'Se envio un correo de confirmacion')
              this.showSuccess("An email has been sent")
            else
              this.showError('An error has ocurred')
        })
        console.log(e)

    }

    showSuccess(success){
      store.addNotification({
        title: "Success!",
        message: success,
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    } 
    showError(error){
      store.addNotification({
        title: "Error",
        message: error,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }


  renderUsers(){
    const self = this;
    var users = [];
    this.state.allCustomer.forEach(function (row) {
    
      users.push(
        <tr>

          <td>{row.idgnt_whitelevel_validacion_email}</td>
          <td>{row.emailValidar}</td>
            <td>
              <Button onClick={() => { self.sendMailV(row)}} className="button-search-filter">
                SEND MAIL
              </Button>
            </td>
        </tr>
      )
    });
    return users;
  }
  renderTable(){
    const self = this;
    var users = [];

    var rowsTitle = [];
    var searchterm = this.state.searchFilterText;
    var key = '';

    this.state.allCustomer.forEach(function (row) {

        if (row.idgnt_whitelevel_validacion_email!== searchterm &&
            row.emailValidar.toLowerCase().indexOf(searchterm.toLowerCase()) === -1)
        return;

      // need to grab the correct match
      if (row.emailValidar.toLowerCase().indexOf(searchterm.toLowerCase()) === -1) {
        var m = row.idgnt_whitelevel_validacion_email.toString().toLowerCase().split(' ');
        for (var i in m)
          if (m[i].indexOf(searchterm.toLowerCase()) !== -1)
            key = m[i];
      } else {
        key = row.emailValidar.toLowerCase();
      }
    
      users.push(
        <tr>
          <td>{row.idgnt_whitelevel_validacion_email}</td>
          <td>{row.emailValidar}</td>
            <td>
              <Button onClick={() => { self.sendMailV(row) }} className="button-search-filter">
                SEND MAIL
              </Button>
            </td>
        </tr>
      )
    });
    return users;
  }


  render(){
    return(
      <div
        style={{
          marginLeft: 64,
          padding: '20px 20px 0 20px',
          width: 'calc(100vw - 64px)',
          position: 'absolute',
          zIndex: -100,
          opacity: (this.props.expanded) ? .5 : 1
        }}
        >

        <div className="container-principal">
          <Col md={12} className="shadow-container-documents">
            <div className='first-container-documents'>
              <div className='header-documents'>
                Unverified clients
              </div>
            </div>
            <div className="container-table-results">
              <FormGroup>
                <ControlLabel className='text-title-documents' >Search by email</ControlLabel>
                <FormControl type="text" className='input-documents' value={this.state.searchFilterText} onChange={(searchFilterText) => { this.setState({ searchFilterText: searchFilterText.target.value }) }} />
              </FormGroup>
              <div className="container-table-results">
              {
                (this.state.searchFilterText === "")
                  ? (
                    <Table responsive>
                <thead className="head-table-documents">
                  <tr>
                    <th>Id</th>
                    <th>Email</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="tbody-table-documents">
                  {this.renderUsers()}
                </tbody>
              </Table>
                  )
                  : (
                    //RESULTADO BUSQUEDA
                    <Table responsive>
                      <thead className="head-table-documents">
                        <tr>
                            <th>Id</th>
                            <th>Email</th>
                            <th></th>
                        </tr>
                      </thead>
                      <tbody className="tbody-table-documents">
                        {this.renderTable()}
                      </tbody>
                    </Table>
                  )
              }
            </div>
              
            </div>
          </Col>
        </div>
      </div>

    )
  }
}export default InvalidClients;