module.exports = {
  getImagesIDs:function documentReader(idgnt_whiteLevel_clientesInfo, callback){
      fetch('https://wlj2amanager.gntapi.com/apiv1/whitelevel/manager/get/ctes/docs',{
      "method": "POST",
      "headers": {
        'Content-Type': 'application/json',
      },
      "body": JSON.stringify({
        "idgnt_whiteLevel_clientesInfo": idgnt_whiteLevel_clientesInfo,
        "operacion": 'RD',
      })
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    })
  },

  getShareDirectors:function getShareDirectors(idgnt_whiteLevel_clientesInfo, callback){
   fetch('https://wlj2amanager.gntapi.com/apiv1/whitelevel/manager/get/single/empresa?idgnt_whiteLevel_clientesInfo=' + idgnt_whiteLevel_clientesInfo, {
      "method": "POST",
      "headers": {
        'Content-Type': 'application/json',
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    })
  },

  contractHandler:function contractHandler(idgnt_whiteLevel_clientesInfo,operacion, callback){
      fetch('https://wlj2avalidate.gntapi.com/api/contratos/ctes/info?idgnt_whiteLevel_clientesInfo=' + idgnt_whiteLevel_clientesInfo + '&operacion='+operacion, {
      "method": "GET",
      "headers": {
        'Content-Type': 'application/json',
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    })
  },

  getmt5Account:function documentReader(mt5Account, callback){
    fetch('https://wlj2amanager.gntapi.com/apiv1/whiteLevel/withdrawal/ctes/historial?mt5Account='+mt5Account,{
      "method": "GET",
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    })
  },
  letPagos:function documentApprover(idgnt_whitelevel_admin_withdrawalctes, operacion, callback){
    fetch('https://wlj2amanager.gntapi.com/apiv1/whitelevel/manager/withdrawal/ctes/pagos/admin?idgnt_whitelevel_admin_withdrawalCtes='+idgnt_whitelevel_admin_withdrawalctes+'&operacion='+operacion ,{
      "method":"POST",
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log('error');
      console.log(error);
    })
  },

  documentReader:function documentReader2(idgnt_whitelevel_documentosctes, callback){
    fetch('https://wlj2amanager.gntapi.com/apiv1/whitelevel/manager/get/documentos?idgnt_whitelevel_documentosctes='+ idgnt_whitelevel_documentosctes,{
      "method":"GET",
    })
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    })
  },
  //https://wlsfxmanager.gntapi.com/apiv1/whitelevel/manager/get/documentos/validadorDoc?idgnt_whitelevel_documentosctes=44&operacion=V
  documentApprover:function documentApprover(tipoTipodocumento, idGnt, operacion, callback){
    fetch(' https://wlj2amanager.gntapi.com/apiv1/whitelevel/manager/get/documentos/validadorDoc?tipo='+ tipoTipodocumento +'&idgnt_whiteLevel_clientesInfo='+ idGnt + '&operacion=' + operacion ,{
      "method":"POST",
    })
    // .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    })
  },

  documentApproverv2:function documentApprover(operacion, ruta, idgnt_whiteLevel_clientesInfo, tipoInfo, callback){
    fetch('https://wlj2amanager.gntapi.com/apiv1/whitelevel/manager/get/documentos/validadorDoc?operacion='+ operacion + '&rutaImagen=' + ruta +'&idgnt_whiteLevel_clientesInfo='+idgnt_whiteLevel_clientesInfo  + '&tipoInfo=' + tipoInfo,{
      "method":"POST",
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    })
  },

  allCustomer:function allCustomer(callback){
    fetch('https://wlj2amanager.gntapi.com/apiv1/whitelevel/manager/get/ctes',{
      "method":"GET",
    })
    .then((response) => response.json())
    .then((responseJson) => {
      //   console.log(responseJson);
      callback(responseJson);
    })
    .catch((error) => {
        console.log(error);
    })
  },

  envioImagen: function envioImagen(idgntDocumentosCtes,tipoCattipocuentas,tipoTipodocumento,idGntCte,operacion,fd,callback){
    fetch('https://gntapi.com:120/api/envioImagen?idgntDocumentosCtes=' + idgntDocumentosCtes + '&tipoCattipocuentas=' + tipoCattipocuentas + '&tipoTipodocumento=' + tipoTipodocumento + '&idGntCte=' + idGntCte + '&operacion=' + operacion,{
      "method":"POST",
      "body": fd
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    })
  },

  ibOperations: function ibOperations(idIB, idgnt_comisionesIB, operacion, idLogin, ibGananica, callback) {
    fetch('https://gntapi.com:107/apiv1/real/ib/IBCOMISIONES2', {
      "method": "POST",
      "headers": {
        'Content-Type': 'application/json',
      },
      "body": JSON.stringify({
        "idIB": idIB,
        "idgnt_comisionesIB": idgnt_comisionesIB,
        "operacion": operacion,
        "idLogin": idLogin,
        "ibGananica": ibGananica,
      })
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson)
    })
    .catch((error) => {
      console.log(error);
    })
  },

  sendUploadImage: function sendUploadImage(idCliente, tipo, ruta, callback) {
    fetch('https://wlj2amanager.gntapi.com/apiv1/whitelevel/manager/hook/documentos', {
      "method": "POST",
      "headers": {
        'Content-Type': 'application/json',
      },
      "body": JSON.stringify({
        "idgnt_whitelevel_documentosctes": 0,
        "idgnt_whiteLevel_clientesInfo": idCliente,
        "idgnt_whitelevel_cattipoImagenes": tipo,
        "rutaImagen": ruta,
        "operacion": "C",
      })
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson)
    })
    .catch((error) => {
      console.log(error);
    })
  },

  getIbAccounts: function getIbAccounts(callback) {
    fetch('https://gntapi.com:107/apiv1/real/ib/IBS2?bandera=real', {
      "method": "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log(responseJson);
        callback(responseJson);
      })
      .catch((error) => {
        console.log(error);
      })
  },

  confirmOperation: function confirmOperation(idIb, ticket, callback) {
    fetch('https://gntapi.com:107/apiv1/real/ib/actualizaDeal?idIB='+idIb+'&ticket='+ticket+'', {
      "method": "POST",
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson)
    })
    .catch((error) => {
      console.log(error);
    })
  },

  finishPayment: function finishPayment(idIB, idgnt_comisionesIB, operacion, callback) {
    fetch('https://gntapi.com:107/apiv1/real/ib/IBCOMISIONES2', {
      "method": "POST",
      "headers": {
        'Content-Type': 'application/json',
      },
      "body": JSON.stringify({
        "idIB": idIB,
        "idgnt_comisionesIB": idgnt_comisionesIB,
        "operacion": operacion
      })
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson)
    })
    .catch((error) => {
      console.log(error);
    })
  },

  getGanancia: function getGanancia(idIB,callback){
   fetch('https://gntapi.com:107/apiv1/usuarios/SELECT_ultimaComisionIb?idgnt_iB_usuarios=' + idIB,{
     "method":"POST",
      "headers": {
        'Content-Type': 'application/json',
      }
   })
   .then((response) => response.json())
   .then((responseJson) => {
     callback(responseJson);
   })
   .catch((error) => {
     console.log(error);
   })
 },

 changeGanancia: function changeGanancia(idgnt_iB_usuarios, id_SUB, gnt_usuarios_gnt_clientesInfo_idgnt_whiteLevel_clientesInfo, gnt_usuarios_idgnt_usuarios, ib_ganancia, operacion, callback){
   console.log(idgnt_iB_usuarios, id_SUB);
   fetch('https://gntapi.com:107/apiv1/usuarios/CRUD_ib',{
    "method": "POST",
    "headers": {
      'Content-Type': 'application/json',
    },
    "body": JSON.stringify({
      "idgnt_iB_usuarios": idgnt_iB_usuarios,
      "id_SUB": id_SUB,
      "gnt_usuarios_gnt_clientesInfo_idgnt_whiteLevel_clientesInfo": gnt_usuarios_gnt_clientesInfo_idgnt_whiteLevel_clientesInfo,
      "gnt_usuarios_idgnt_usuarios": gnt_usuarios_idgnt_usuarios,
      "ib_ganancia": ib_ganancia,
      "operacion": operacion
    })
  })
  .then((response) => response.json())
  .then((responseJson) => {
    callback(responseJson);
  })
  .catch((error) => {
    console.log(error);
  })
  },
  getSubIbInfo: function getSubIbInfo(idIb, idSubIB, operacion, callback){
    fetch('https://gntapi.com:461/apiv1/real/comisiones/subib?idIB=' + idIb + '&idSubIB=' + idSubIB + '&operacion=' + operacion,{
     "method": "POST",
     "headers": {
       'Content-Type': 'application/json',
     }
    })
    .then((response) => response.json())
    .then((responseJson) => {
     callback(responseJson);
    })
    .catch((error) => {
     console.log(error);
    })
  },

  getIbSubIbs: function getIbSubIbs(idIb, callback){
    fetch('https://gntapi.com:461/apiv1/real/comisiones/selectsubib?idIB=' + idIb,{
     "method": "GET",
     "headers": {
       'Content-Type': 'application/json',
     }
    })
    .then((response) => response.json())
    .then((responseJson) => {
     callback(responseJson);
    })
    .catch((error) => {
     console.log(error);
    })
  },

  getSubIbSubIbs: function getSubIbSubIbs(idIb, callback){
    fetch('https://gntapi.com:461/apiv1/real/comisiones/clientesSub?idSubIB=' + idIb,{
     "method": "GET",
     "headers": {
       'Content-Type': 'application/json',
     }
    })
    .then((response) => response.json())
    .then((responseJson) => {
     callback(responseJson);
    })
    .catch((error) => {
     console.log(error);
    })
  },

  getAllSubIbs: function getAllSubIbs(idgnt_subIbs,gnt_iB_usuarios_gnt_usuarios_idgnt_usuarios,gnt_iB_usuarios_gnt_usuarios_gnt_clientesInfo_idgnt_whiteLevel_clientesInfo,gnt_iB_usuarios_idgnt_iB_usuarios,idSubIB,ib_ganancia,operacion,callback){
    fetch('https://gntapi.com:461/apiv1/real/comisiones/crudgntsubib',{
     "method": "POST",
     "headers": {
       'Content-Type': 'application/json',
     },
     "body": JSON.stringify({
       "idgnt_subIbs": idgnt_subIbs,
       "gnt_iB_usuarios_gnt_usuarios_idgnt_usuarios": gnt_iB_usuarios_gnt_usuarios_idgnt_usuarios,
       "gnt_iB_usuarios_gnt_usuarios_gnt_clientesInfo_idgnt_whiteLevel_clientesInfo": gnt_iB_usuarios_gnt_usuarios_gnt_clientesInfo_idgnt_whiteLevel_clientesInfo,
       "gnt_iB_usuarios_idgnt_iB_usuarios": gnt_iB_usuarios_idgnt_iB_usuarios,
       "idSubIB": idSubIB,
       "ib_ganancia": ib_ganancia,
       "operacion": operacion,
     })
    })
    .then((response) => response.json())
    .then((responseJson) => {
     callback(responseJson);
    })
    .catch((error) => {
     console.log(error);
    })
  },

  agregarIb: function agregarIb(idIB,correoCTE,cuentaMeta,operacion,callback){
    fetch('https://gntapi.com:461/apiv1/real/ibs/agregador',{
     "method": "POST",
     "headers": {
       'Content-Type': 'application/json',
     },
     "body": JSON.stringify({
       "idIB": idIB,
       "correoCTE": correoCTE,
       "cuentaMeta": cuentaMeta,
       "operacion": operacion,
     })
    })
    .then((response) => response.json())
    .then((responseJson) => {
     callback(responseJson);
    })
    .catch((error) => {
     console.log(error);
    })
  },

  listaClientesIb: function listaClientesIb(IB_usuarios,callback){
    fetch('https://gntapi.com:461/apiv1/real/ib/infoCtesIb?idgnt_IB_usuarios='+ encodeURIComponent(IB_usuarios) ,{
      "method":"GET",
       "headers": {
         'Content-Type': 'application/json',
       }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {

    })
  },

  loginAurea: function loginAurea(email, password, callback){
    fetch('https://wlj2amanager.gntapi.com/apiv1/whitelevel/manager/login',{
     "method": "POST",
     "headers": {
       'Content-Type': 'application/json',
     },
     "body": JSON.stringify({
       "usuario": email,
       "contrasena": password,
     })
    })
    .then((response) => response.json())
    .then((responseJson) => {
     callback(responseJson);
    })
    .catch((error) => {
     console.log(error);
    })
  },
  ActualizarBalance: function ActualizarBalance(idIB,idSIB,operacion,ib_ganancia,callback){
    fetch('https://wlj2amanager.gntapi.com/apiv1/whitelevel/manager/set/balanceIB',{
     "method": "POST",
     "headers": {
       'Content-Type': 'application/json',
     },
     "body": JSON.stringify({
       "idIB": idIB,
       "idSIB": idSIB,
       "ib_ganancia": ib_ganancia,
       "operacion": operacion,
     })
    })
    .then((response) => response.json())
    .then((responseJson) => {
     callback(responseJson);
    })
    .catch((error) => {
     console.log(error);
    })
  },
  ActualizarImagen: function ActualizarImagen(idGntCte,fd,callback){
    fetch('https://wlj2amanager.gntapi.com/apiv1/whiteLevel/manager/carga/imagenes/cte?idgnt_whiteLevel_clientesInfo='+idGntCte,{
      "method":"POST",
      "body": fd
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    })
  },
  //https://gntapi.com:666/apiv1/control/imaganes/validacion
  ValidarImagen: function ValidarImagen(fd,callback){
    fetch('https://gntapi.com:666/apiv1/control/imaganes/validacion',{
      "method":"POST",
      "body": fd
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    })
  },
  EnviarContraseña: function EnviarContraseña(email,callback){
    fetch('https://wlj2a.gntapi.com/apiv1/whitelevel/getUsers?usuario='+email,{
      "method":"Get",
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    })
  },
   allInvalidCustomers: function allInvalidCustomers(callback){
    fetch('https://wlj2amanager.gntapi.com/apiv1/whitelevel/manager/usuarios/pend',{
      "method":"Get",
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    })
  },
  SendConfirmationMail: function SendConfirmationMail(id,email,from,msg,type,callback){
    fetch('https://wlj2amanager.gntapi.com/apiv1/whitelevel/manager/usuarios/pend/reenvio',{
      "method":"POST",
      "headers": {
        'Content-Type': 'application/json',
      },
      "body": JSON.stringify({
        "idgnt_whitelevel_validacion_email": id,
        "emailValidar": email,
        "envio": from,
        "mensaje": msg,
        "tipo": type,
      })
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    })
  },
  UpgradeIB: function UpgradeIB(id,callback){
    fetch('https://wlj2a.gntapi.com/apiv1/whiteLevel/usuario/ibs/cuentas',{
     "method": "POST",
     "headers": {
       'Content-Type': 'application/json',
     },
     "body": JSON.stringify({
       "idgnt_whiteLevel_clientesInfo": id,
     })
    })
    .then((response) => response.json())
    .then((responseJson) => {
     callback(responseJson);
    })
    .catch((error) => {
     console.log(error);
    })
  },
  SendAdsMail: function SendAdsMail(subject,msg,operation,callback){
    fetch('https://wlj2amanager.gntapi.com/apiv1/whitelevel/manager/envio/correo',{
      "method":"POST",
      "headers": {
        'Content-Type': 'application/json',
      },
      "body": JSON.stringify({
        "subjectCte": subject,
        "mensajeCte": msg,
        "operacion": operation,
      })
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    })
  },
}
