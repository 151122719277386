import React, {Component} from 'react';
import { Col,Row,Tab, Nav, NavItem, Table, Button,Image, FormGroup,FormControl, Modal, ControlLabel} from 'react-bootstrap';
import { store } from 'react-notifications-component';
import { Spinner } from 'react-activity';
import 'react-notifications-component/dist/theme.css';
import Backend from '../../../backend';
import CheckImage from '../../../img/check.svg';
import backend from '../../../backend';

function formatMoney(n,c,d,t){
var n = n,
    c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-$" : "$",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;
   return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
 };

class tradingAccounts extends Component{
  constructor(props){
    super(props)
    this.state = {
      selected: 'home',
      expanded: false,
      listaAllCustomer: [],
      show: false,
      cambio_idIB: "",
      cambio_idSIB: "",
      cambio_operacion: "",
      cambio_ib_ga: "",
      searchFilterText: '',
      solicitaIB:true,
      showIBModal:false,
      btnDisabled:false,
      spinner:false,
    }
  }

  subeIB(user){
    this.setState({ showIBModal: !this.state.showIBModal, idUsuario: user.idgnt_whiteLevel_clientesInfo, userName: user.clienteNombre + ' ' + user.clientesApellidos })
  }

  upgradeIB(idUser){
    this.setState({ spinner: true, btnDisabled:true });
    Backend.UpgradeIB(idUser,response => {
      response = JSON.parse(response)
      //console.log(response[0].mensaje)
      if(response[0].mensaje.indexOf("Cuenta creada con ID") !== -1){
        this.showSuccess('Upgraded correctly')
        this.setState({showIBModal: !this.state.showIBModal, idUsuario:'', userName:'', idUsuario:'', spinner:false, btnDisabled:false})
        Backend.allCustomer(response => {
          response = JSON.parse(response)
          // console.log(response);
          this.setState({
            listaAllCustomer: response,
          })
          this.AccountTypeGain()
        });
      }
      else {
        this.showError('An error ocurred, try again')
        this.setState({ spinner: false, btnDisabled:false });
      }
    })
  }

  componentWillMount(){
    Backend.allCustomer(response => {
      response = JSON.parse(response)
      // console.log(response);
      this.setState({
        listaAllCustomer: response,
      })
      this.AccountTypeGain()
    });
  }
  
  AccountTypeGain(){
    const { listaAllCustomer: OldlistaAllCustomer } = this.state;
   
    const listaAllCustomer = OldlistaAllCustomer.map(element =>{
     
      if(element.IB === 0 && element.SIB === 0){
        element.Gain="---"
      }else if(element.SIB === 0){
        element.Gain=element.ib_ganancia
      }else{
        element.Gain=element.sib_ganancia
      }
    })

    this.setState(listaAllCustomer);

    
  }

  setStateGain(id,number){
    const { listaAllCustomer: OldlistaAllCustomer } = this.state;

    const listaAllCustomer = OldlistaAllCustomer.map(element =>{
      if(element.idgnt_whiteLevel_clientesInfo===id){
        element.Gain=number
      }
    })

    this.setState(listaAllCustomer);
    
  }

  updateGain(idIB,idSIB,operacion,ib_ga){
    this.setState({
      show: !this.state.show
    })
    Backend.ActualizarBalance(idIB,idSIB,operacion,ib_ga,response => {
      response = JSON.parse(response)
      // console.log(response[0].mensaje)
      if(response[0].mensaje==="Ganancia actualizada")
        this.showSuccess('Updated correctly')
      else if(response[0].mensaje==="Ganancia excedida")
      this.showError('Profit exceeded')
    });
    
  }
 
  ModalConfirm(client){
    
    let operacion
    if(client.SIB != 0){
      operacion="CBS"
    }else{
      operacion="CBI"
    }
    this.setState({
      show: !this.state.show,
      cambio_idIB: client.IB,
      cambio_idSIB: client.SIB,
      cambio_operacion: operacion,
      cambio_ib_ga: client.Gain,
    })
    
  }

  showSuccess(success){
    store.addNotification({
      title: "Success!",
      message: success,
      type: "success",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    })
  } 

  showError(error){
    store.addNotification({
      title: "Error",
      message: error,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    })
  }

  showWarning(warning){
    store.addNotification({
      title: "Warning",
      message: warning,
      type: "warning",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    })
  }

  enviarContrseña(email){
    //console.log(email)
    Backend.EnviarContraseña(email,response=>{
      //console.log(response)
      if(response == "Se envio la contraseña correctamente")
      this.showSuccess("Password sent")
      else this.showError("An error ocurred")
    })
  }

  renderType(_client){
    if(_client.empresa == 1){
      return "Company"
    }
    else  if(_client.IB == 0 && _client.SIB == 0){
      return 'Client'
    }else if(_client.SIB == 0){
      return 'IB'
    }else{
      return 'Sub IB'
    }
  }
 
  renderTable() {
    const self = this;
    // We need to get each row and store it in an array
    var rowsTitle = [];
    var search = [];
    var searchterm = this.state.searchFilterText; // need this or it doesnt work
    var key = '';
    this.state.listaAllCustomer.forEach(function (row) {
      
      if (row.clienteNombre.toLowerCase().indexOf(searchterm.toLowerCase()) === -1 &&
        row.idgnt_whiteLevel_clientesInfo.toString().toLowerCase().indexOf(searchterm.toLowerCase()) === -1 &&
        row.cuentasMeta.toLowerCase().indexOf(searchterm.toLowerCase()) === -1 &&
        row.clientesApellidos.toLowerCase().indexOf(searchterm.toLowerCase()) === -1
      )
        return;

      // need to grab the correct match
      if (row.clienteNombre.toLowerCase().indexOf(searchterm.toLowerCase()) === -1) {
        var m = row.idgnt_whiteLevel_clientesInfo.toString().toLowerCase().split(' ');
        for (var i in m)
          if (m[i].indexOf(searchterm.toLowerCase()) !== -1)
            key = m[i];
      } else {
        key = row.clienteNombre.toLowerCase();
      }
      rowsTitle.push(
        <tr>
          <td>{row.idgnt_whiteLevel_clientesInfo}</td>
          <td>{self.renderType(row)}</td>
          <td>{row.clienteNombre}</td>
          <td>{row.clientesApellidos}</td>
          <td>{(row.ValidateAll)?<Image src={CheckImage} style={{height:20}} />:'Invalidated'}</td>
          <td>{row.cuentasMeta}</td>
          <td>
            { 
            row.Gain != "---"
            ?(
              <FormGroup className="input-button">
                <FormControl
                    className='login-textinput'
                    value={row.Gain}
                    onChange={(Gain)=> {self.setStateGain(row.idgnt_whiteLevel_clientesInfo,Gain.target.value)}}
                    type="text"  />
                <Button onClick={()=>self.ModalConfirm(row)}>ok</Button>
              </FormGroup>
            )
            :(
              <p>----</p>
            )

            }
                
              
          </td>
          <td>{formatMoney(row.saldoBalance)}</td>
          <td>
            { 
            row.Gain != "---"
            ?formatMoney(row.comissions)
            :<p></p>
            }
          </td>
          <td>
            <Button onClick={() => { self.props._clientPicked(row) }} className="button-search-filter">
              View
            </Button>
          </td>
          <td>
            <Button onClick={() => { self.enviarContrseña(row.clienteEmail) }} className="button-search-filter">
              Send Password
            </Button>
          </td>
          {
            row.IBAlert == 1          
          ? <td>
            <Button onClick={() => {self.subeIB(row)}} className="button-search-filter">
              Go IB
            </Button>
          </td>
          : <td></td>
          }


         
        </tr>
      );
    });

    return (
      rowsTitle
    );
  }

  renderUsers(){
    const self = this;
    var users = [];
    this.state.listaAllCustomer.forEach(function (row) {
      users.push(
        <tr>
          
          <td>{row.idgnt_whiteLevel_clientesInfo}</td>
          <td>{self.renderType(row)}</td>
          <td>{row.clienteNombre}</td>
          <td>{row.clientesApellidos}</td>
          <td>{(row.ValidateAll)?<Image src={CheckImage} style={{height:20}} />:'Invalidated'}</td>
          <td>{row.cuentasMeta}</td>
          <td>
            { 
            row.Gain != "---"
            ?(<FormGroup className="input-button">
                <FormControl
                    className='login-textinput'
                    value={row.Gain}
                    onChange={(Gain)=> {self.setStateGain(row.idgnt_whiteLevel_clientesInfo,Gain.target.value)}}
                    type="text"  />
                <Button onClick={()=>self.ModalConfirm(row)}>ok</Button>
              </FormGroup>)
            :(<p>----</p>)}
          </td>
          <td>{formatMoney(row.saldoBalance)}</td>
          <td>
            { 
            row.Gain != "---"
            ?formatMoney(row.comissions)
            :<p></p>
            }
          </td>
            <td>
              <Button onClick={() => { self.props._clientPicked(row) }} className="button-search-filter">
                View
              </Button>
            </td>
            <td>
            <Button onClick={() => { self.enviarContrseña(row.clienteEmail) }} className="button-search-filter">
              Send Password
            </Button>
          </td>
          {
            row.IBAlert == 1          
          ? <td>
            <Button onClick={() => {self.subeIB(row)}} className="button-search-filter">
              Go IB
            </Button>
          </td>
          : <td></td>
          }
        </tr>
      )
    });
    return users;
  }

  render(){
    return(
      <div
        style={{
          marginLeft: 64,
          padding: '20px 20px 0 20px',
          width: 'calc(100vw - 64px)',
          position: 'absolute',
          zIndex: -100,
          opacity: (this.props.expanded) ? .5 : 1
        }}
        >
        <div className="container-principal">
          <Col md={12} className="shadow-container-documents">
            <div className='first-container-documents'>
              <div className='header-documents'>
                Trading Accounts
              </div>
            </div>
            <div className="container-table-results">
              <FormGroup>
                <ControlLabel className='text-title-documents' >Search by name</ControlLabel>
                <FormControl type="text" className='input-documents' value={this.state.searchFilterText} onChange={(searchFilterText) => { this.setState({ searchFilterText: searchFilterText.target.value }) }} />
              </FormGroup>
              <div className="container-table-results">
              {
                (this.state.searchFilterText === "")
                  ? (
                    <Table responsive>
                <thead className="head-table-documents">
                  <tr>
                    <th>Id</th>
                    <th>Acc Type</th>
                    <th>Name</th>
                    <th>Last Name</th>
                    <th>Validated</th>
                    <th>Accounts</th>
                    <th>Gain</th>
                    <th>Balance</th>
                    <th>Comissions</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="tbody-table-documents">
                  {this.renderUsers()}
                </tbody>
              </Table>
                  )
                  : (
                    //RESULTADO BUSQUEDA
                    <Table responsive>
                      <thead className="head-table-documents">
                        <tr>
                          <th>Id</th>
                          <th>Acc Type</th>
                          <th>Name</th>
                          <th>Last Name</th>
                          <th>Validated</th>
                          <th>Accounts</th>
                          <th>Gain</th>
                          <th>Balance</th>
                          <th>Comissions</th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="tbody-table-documents">
                        {this.renderTable()}
                      </tbody>
                    </Table>
                  )
              }
            </div>
              
            </div>
          </Col>
        </div>
        <Modal size="sm" show={this.state.show} onHide={()=>this.setState({show: !this.state.show})}>

        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>

      <Modal.Body className="Modal-Body">Do you confirm the commission change to {this.state.cambio_ib_ga} USD?</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={()=>this.setState({show: !this.state.show})}>
            Cancel
          </Button>
          <Button variant="primary" onClick={()=>this.updateGain(this.state.cambio_idIB,this.state.cambio_idSIB,this.state.cambio_operacion,this.state.cambio_ib_ga)}>
            Confirm
          </Button>
        </Modal.Footer>

      </Modal>
      
      <Modal size="sm" show={this.state.showIBModal} onHide={()=>this.setState({showIBModal: !this.state.showIBModal, idUsuario:'', userName:''})}>

        <Modal.Header closeButton>
          <Modal.Title>Upgrade IB</Modal.Title>
        </Modal.Header>

      <Modal.Body className="Modal-Body">Are you sure you want to upgrade {this.state.userName} to an IB account?</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={()=>this.setState({showIBModal: !this.state.showIBModal, idUsuario:'', userName:''})} >
            Cancel
          </Button>
          <Button variant="primary" onClick={()=>this.upgradeIB(this.state.idUsuario)} disabled={this.state.spinner} >
            { this.state.spinner
              ? <Spinner color="#333" size={10} speed={1} animating={this.state.showLoad} disabled />
              : "Confirm"
            }
          </Button>
        </Modal.Footer>

      </Modal>
      </div>
    )
  }
}export default tradingAccounts;
